
import { defineComponent, onMounted, reactive, ref, computed } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getOrganization } from "@/core/services/JwtService";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import Multiselect from "@vueform/multiselect";
import { searchDictCARC } from "@/api/claimAdjustmentReasonCode.api";
import { searchDictCAGC } from "@/api/claimAdjustmentGroupCode.api";
import { searchDictRARC } from "@/api/remittanceAdviceRemarkCode.api";
import {
  searchInsurancePaymentDistributions,
  addInsurancePaymentDistribution,
  updateInsurancePaymentDistribution,
} from "@/api/insurancePaymentDistribution.api";
import { updatePaymentInfo } from "@/api/claim.api";
import useVuelidate from "@vuelidate/core";
import { required, helpers, maxLength } from "@vuelidate/validators";
import { subNumbers, sumNumbers } from "@/utility";
import {
  ClaimActions,
  defaultReasonCode,
  defaultReasonCodesgroupCode,
} from "../claims/claim.model";

export interface Data {
  claimActions: any;
  selectedClaim: any;
  isLoaded: boolean;
  displayErrors: boolean;
  dict: {
    carcRarcCodes: any[];
    cagcCodes: any[];
    nextParties: any[];
  };
}

export default defineComponent({
  name: "ClaimServicePaymentPosting",
  props: ["claim", "payment", "remaining"],
  components: { DecimalInput, Multiselect },
  setup(props, ctx) {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      claimActions: {
        Transfer: ClaimActions.Transfer,
        Hold: ClaimActions.Hold,
      },
      selectedClaim: props.claim,
      isLoaded: false,
      displayErrors: false,
      dict: {
        carcRarcCodes: [],
        cagcCodes: [],
        nextParties: [],
      },
    });

    const paymentValidation = () =>
      props.remaining >= 0 &&
      data.selectedClaim.totalCharges >=
        sumNumbers(previousPayments.value, claimPayment.value);

    const nextValidation = (nextParty) =>
      (data.selectedClaim.action !== ClaimActions.Transfer &&
        data.selectedClaim.action !== ClaimActions.Hold) ||
      nextParty !== null;

    const validationRules = {
      selectedClaim: {
        payment: {
          amt: helpers.withMessage("Invalid", paymentValidation),
          $lazy: true,
        },

        nextResponsiblePartyId: {
          req: helpers.withMessage("Required", nextValidation),
        },
        action: { req: helpers.withMessage("Required", required) },
        notes: { maxLength: maxLength(250) },
        claimLineItems: {
          $each: helpers.forEach({
            currentDistribution: {},
          }),
        },
      },
    };

    let v$ = useVuelidate(validationRules, data as never) as any;

    onMounted(async () => {
      organizationId.value = getOrganization();
      let carc = await searchDictCARC({ search: "" });
      let rarc = await searchDictRARC({ search: "" });
      let cagc = await searchDictCAGC({ search: "" });

      data.dict.cagcCodes = cagc;
      data.dict.carcRarcCodes = [...carc, ...rarc];

      if (data.selectedClaim.encounter.secondaryPayer) {
        data.dict.nextParties.push({
          id: data.selectedClaim.encounter.secondaryPayerId,
          name: `${data.selectedClaim.encounter.secondaryPayer.name} (${data.selectedClaim.encounter.secondaryPayer.subscriberIdNumber})`,
        });
      }

      if (data.selectedClaim.encounter.tertiaryPayer) {
        data.dict.nextParties.push({
          id: data.selectedClaim.encounter.tertiaryPayerId,
          name: `${data.selectedClaim.encounter.tertiaryPayer.name} (${data.selectedClaim.encounter.tertiaryPayer.subscriberIdNumber})`,
        });
      }

      data.dict.nextParties.push({
        id: "Self",
        name: "Self",
      });

      for (let item of data.selectedClaim.claimLineItems) {
        // get current and previous distributions
        let current = await searchInsurancePaymentDistributions({
          InsurancePaymentId: props.payment.id,
          ClaimLineItemId: item.id,
        });

        if (current.length > 0) {
          item.currentDistribution = current[0];
        }

        let past = await searchInsurancePaymentDistributions({
          InsurancePaymentId: null,
          ClaimLineItemId: item.id,
        });

        // remove current
        let index = past.findIndex(
          (el) => el.insurancePaymentId === props.payment.id
        );
        item.pastDistributions = index === -1 ? past : past.toSpliced(index, 1);

        // initialize reason codes
        if (item.currentDistribution.reasonCodes == undefined) {
          item.currentDistribution.reasonCodes = new Array<any>();
        }

        if (item.currentDistribution.reasonCodes?.length < 1) {
          item.currentDistribution.reasonCodes.push({
            groupCode: null,
            reasonCode: null,
          });
        }
      }

      if (data.selectedClaim.nextResponsiblePartyId === null) {
        data.selectedClaim.nextResponsiblePartyId = "Self";
      }

      ctx.emit("totalForClaim", claimPayment.value);
      data.isLoaded = true;
    });

    function clear(item) {
      item.currentDistribution.allowed = null;
      item.currentDistribution.payment = null;
      item.currentDistribution.adjustment = null;
      item.currentDistribution.coPayCoInsurance = null;
      item.currentDistribution.deductible = null;
      item.currentDistribution.patientResponsibility = null;
      item.currentDistribution.reasonCodes = [
        {
          groupCode: null,
          reasonCode: null,
        },
      ];

      item.currentDistribution.inputCompleted = false;
    }

    function cancel() {
      let text = "Are you sure you want to leave without saving changes?";

      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          ctx.emit("cancel");
        }
      });
    }

    async function save() {
      let validateResults = await v$.value.$validate();
      if (!validateResults) {
        data.displayErrors = true;
        return;
      } else {
        data.displayErrors = false;
      }

      let restId = data.selectedClaim.nextResponsiblePartyId;

      if (restId === "Self") {
        restId = null;
      }

      let info = {
        id: data.selectedClaim.id,
        nextResponsiblePartyId: restId,
        action: data.selectedClaim.action,
        notes: data.selectedClaim.notes,
      };

      await updatePaymentInfo(info);

      for (let item of data.selectedClaim.claimLineItems) {
        if (item.currentDistribution?.id) {
          await updateInsurancePaymentDistribution(item.currentDistribution);
        } else {
          let res = await addInsurancePaymentDistribution({
            InsurancePaymentId: props.payment.id,
            ClaimLineItemId: item.id,
            serviceId: item.serviceId,
            Allowed: item.currentDistribution.allowed,
            Payment: item.currentDistribution.payment,
            Adjustment: item.currentDistribution.adjustment,
            CoPayCoInsurance: item.currentDistribution.coPayCoInsurance,
            Deductible: item.currentDistribution.deductible,
            PatientResponsibility:
              item.currentDistribution.patientResponsibility,
            ReasonCodes: item.currentDistribution.reasonCodes,
          });

          item.currentDistribution.id = res;
        }
      }

      ctx.emit("savePayment");
      ctx.emit("cancel");
    }

    const previousPayments = computed(() => {
      let sum = 0;

      for (const line of data.selectedClaim.claimLineItems) {
        if (line.pastDistributions) {
          for (const dist of line.pastDistributions) {
            sum = sumNumbers(sum, dist.payment);
          }
        }
      }

      return sum; // TODO
    });

    const claimPayment = computed(() => {
      let sum = 0;
      (data.selectedClaim.claimLineItems as Array<any>).forEach(
        (item) => (sum = sumNumbers(sum, item.currentDistribution.payment) ?? 0)
      );
      return sum;
    });

    const claimAllowed = computed(() => {
      let sum = 0;
      (data.selectedClaim.claimLineItems as Array<any>).forEach(
        (item) => (sum = sumNumbers(sum, item.currentDistribution.allowed) ?? 0)
      );
      return sum;
    });

    function addReasonCode(claimLine) {
      claimLine.currentDistribution.reasonCodes.push({
        groupCode: null,
        reasonCode: null,
      });
    }

    function removeReasonCode(claimLine, index) {
      if (claimLine.currentDistribution.reasonCodes.length == 1) {
        return;
      }

      claimLine.currentDistribution.reasonCodes.splice(index, 1);
    }

    function handleAllowed(claimLine) {
      if (claimLine.currentDistribution.inputCompleted) return;
      if (claimLine.currentDistribution.allowed !== undefined) {
        if (claimLine.currentDistribution.allowed === 0) {
          claimLine.currentDistribution.adjustment = 0;
        } else if (data.selectedClaim?.claimPayerOrder != 0) {
          claimLine.currentDistribution.adjustment = 0;
        } else {
          claimLine.currentDistribution.adjustment = subNumbers(
            claimLine.totalCharges,
            claimLine.currentDistribution.allowed
          );
        }
      }
      claimLine.currentDistribution.inputCompleted = true;
    }

    function handlePayment(claimLine) {
      ctx.emit("paymentUpdated", claimPayment.value);

      if (
        claimLine.currentDistribution.payment !== undefined &&
        claimLine.currentDistribution.allowed !== undefined
      ) {
        claimLine.currentDistribution.patientResponsibility = subNumbers(
          subNumbers(
            claimLine.totalCharges,
            claimLine.currentDistribution.adjustment
          ),
          claimLine.currentDistribution.payment
        );
      }
      setCoCode(claimLine);
    }

    function handleAdjustment(claimLine) {
      if (claimLine.currentDistribution.inputCompleted) return;
      if (
        claimLine.currentDistribution.adjustment !== undefined &&
        claimLine.currentDistribution.allowed !== 0
      ) {
        claimLine.currentDistribution.allowed = subNumbers(
          claimLine.totalCharges,
          claimLine.currentDistribution.adjustment
        );
      }
      setCoCode(claimLine);
      claimLine.currentDistribution.inputCompleted = true;
    }

    function handlePatientResponsibility(claimLine) {
      setCoCode(claimLine);
    }

    function setCoCode(claimLine) {
      if (
        claimLine.currentDistribution.patientResponsibility == 0 &&
        claimLine.currentDistribution.reasonCodes.length == 1
      ) {
        claimLine.currentDistribution.reasonCodes[0].groupCode =
          defaultReasonCodesgroupCode;
        claimLine.currentDistribution.reasonCodes[0].reasonCode =
          defaultReasonCode;
      }
      if (
        claimLine.currentDistribution.patientResponsibility != 0 &&
        claimLine.currentDistribution.reasonCodes.length == 1 &&
        claimLine.currentDistribution.reasonCodes[0].groupCode ==
          defaultReasonCodesgroupCode &&
        claimLine.currentDistribution.reasonCodes[0].reasonCode ==
          defaultReasonCode
      ) {
        claimLine.currentDistribution.reasonCodes[0].groupCode = null;
        claimLine.currentDistribution.reasonCodes[0].reasonCode = null;
      }
    }

    const totalAllowed = computed(() => {
      let sum = 0;
      (data.selectedClaim.claimLineItems as Array<any>).forEach(
        (item) => (sum = sumNumbers(sum, item.currentDistribution.allowed) ?? 0)
      );
      return sum;
    });

    const totalAdjustment = computed(() => {
      let sum = 0;
      (data.selectedClaim.claimLineItems as Array<any>).forEach(
        (item) =>
          (sum = sumNumbers(sum, item.currentDistribution.adjustment) ?? 0)
      );
      return sum;
    });

    const totalCopay = computed(() => {
      let sum = 0;
      (data.selectedClaim.claimLineItems as Array<any>).forEach(
        (item) =>
          (sum =
            sumNumbers(sum, item.currentDistribution.coPayCoInsurance) ?? 0)
      );
      return sum;
    });

    const totalDeductible = computed(() => {
      let sum = 0;
      (data.selectedClaim.claimLineItems as Array<any>).forEach(
        (item) =>
          (sum = sumNumbers(sum, item.currentDistribution.deductible) ?? 0)
      );
      return sum;
    });

    const totalResponsibility = computed(() => {
      let sum = 0;
      (data.selectedClaim.claimLineItems as Array<any>).forEach(
        (item) =>
          (sum =
            sumNumbers(sum, item.currentDistribution.patientResponsibility) ??
            0)
      );

      return sum;
    });

    function getClaimActions() {
      const claimActions = [
        { name: "Transfer", id: 2 },
        { name: "Hold", id: 4 },
        { name: "Close", id: 5, disabled: isCloseDisabled() },
      ];
      if (
        data.selectedClaim.action &&
        data.selectedClaim.action === ClaimActions.Close &&
        isCloseDisabled()
      )
        data.selectedClaim.action = null;
      return claimActions;
    }

    function isCloseDisabled() {
      const res = data.selectedClaim.claimLineItems.find((item) => {
        return item.currentDistribution.patientResponsibility !== 0;
      });
      return typeof res != "undefined";
    }

    return {
      cancel,
      clear,
      data,
      save,
      subNumbers,
      sumNumbers,
      previousPayments,
      claimPayment,
      claimAllowed,
      getClaimActions,
      addReasonCode,
      removeReasonCode,
      handleAllowed,
      handlePayment,
      handleAdjustment,
      handlePatientResponsibility,
      v$,
      totalAllowed,
      totalAdjustment,
      totalCopay,
      totalDeductible,
      totalResponsibility,
    };
  },
});
