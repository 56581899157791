
import { defineComponent, onMounted, ref, reactive, nextTick } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { getPanel } from "@/api/encounter.api";
import { searchClaimsForPayment } from "@/api/claim.api";
import { useStore } from "vuex";
import { sumNumbers } from "@/utility";
import debounce from "lodash.debounce";
import useFilters from "@/modules/common/useFilters";
import { deleteForClaim } from "@/api/insurancePaymentDistribution.api";

export interface StatusCode {
  code: number;
  description: string;
}
export interface Data {
  items: any[];
  payment: any;
  selectedClaim: any;
  keyword: string;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  dict: {
    facilities: { id: string }[];
    claimsActionCodes: StatusCode[];
  };
  postingErrors: string[];
}
export default defineComponent({
  name: "ClaimsAlreadyDistributed",
  props: ["payment"],
  components: {},
  setup(props, ctx) {
    const store = useStore();
    const orderList = [
      {
        name: "Claim ID",
        key: "claimId",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Patient",
        key: "patientFull",
        IsAdvancedOrderBy: true,
        isFilter: true,
        keyword: null,
      },
      {
        name: "Date of Service",
        key: "dos",
        IsAdvancedOrderBy: true,
        isFilter: true,
        keyword: null,
      },
      {
        name: "Provider",
        key: "provider",
        IsAdvancedOrderBy: true,
        isFilter: true,
        keyword: null,
      },
      {
        name: "Facility",
        key: "encounter.facility.name",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Billed Amount",
        key: "totalCharges",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Total Allowed Amount",
        key: "totalAllowedAmount",
        IsAdvancedOrderBy: true,
        isFilter: true,
        keyword: null,
      },
      {
        name: "Total Payments",
        key: "claimPayment",
        IsAdvancedOrderBy: true,
        isFilter: true,
        keyword: null,
      },
      {
        name: "Total Adjustments",
        key: "totalAdjustments",
        IsAdvancedOrderBy: true,
        isFilter: true,
        keyword: null,
      },
      {
        name: "Total Patient Responsibility",
        key: "totalResponsibility",
        IsAdvancedOrderBy: true,
        isFilter: true,
        keyword: null,
      },
      {
        name: "Action",
        key: "action",
        isFilter: true,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      payment: undefined,
      items: [],
      selectedClaim: null,
      keyword: "",
      orderBy: "claimId",
      IsAdvancedOrderBy: false,
      isDecr: false,
      panel: {},
      dict: {
        facilities: [],
        claimsActionCodes: [],
      },
      postingErrors: [],
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "claimId";
      data.IsAdvancedOrderBy = false;
      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.payment = props.payment;

      const panel = await getPanel();
      data.dict.facilities = panel.facilities;
      data.dict.claimsActionCodes = store.getters.allClaimActions.sort((a, b) =>
        a.description.localeCompare(b.description)
      );

      await getAll();

      calculateTotals();
    });

    function calculateTotals() {
      let totalTotalPayments = 0;
      for (let claim of data.items) {
        claim.totalAllowed = totalAllowed(claim);
        claim.totalPayments = totalPayments(claim);
        claim.totalAdjustment = totalAdjustment(claim);
        claim.totalResponsibility = totalResponsibility(claim);

        totalTotalPayments = sumNumbers(
          totalTotalPayments,
          claim.totalPayments
        );
      }

      ctx.emit("totalPosted", totalTotalPayments);
    }

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function clearSearch() {
      await nextTick();
      await search();
    }

    async function debounceSearch() {
      await debounce(search, useFilters().debounceMs)();
    }

    async function search() {
      await nextTick();
      await getAll();
    }

    async function getAll() {
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }

      let request = {
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        globalSearchExtended: data.keyword,
        currentInsurancePaymentId: data.payment.id,
        hasPayment: true,
        alreadyDistributed: true,
      };

      const res = await searchClaimsForPayment(request);
      data.items = res;

      for (let claim of data.items) {
        claim.totalAllowed = totalAllowed(claim);
        claim.totalPayments = totalPayments(claim);
        claim.totalAdjustment = totalAdjustment(claim);
        claim.totalResponsibility = totalResponsibility(claim);
      }
    }

    function selectClaim(claim) {
      for (let item of claim.claimLineItems) {
        item.currentDistribution = {};
      }

      ctx.emit("claimSelected", claim);
    }

    function totalAllowed(claim) {
      let sum = 0.0;

      for (let line of claim.claimLineItems) {
        if (line.distributions) {
          for (let dist of line.distributions) {
            if (dist.insurancePaymentId == data.payment.id) {
              sum += dist.allowed;
            }
          }
        }
      }

      return sum;
    }

    function totalPayments(claim) {
      let sum = 0.0;

      for (let line of claim.claimLineItems) {
        if (line.distributions) {
          for (let dist of line.distributions) {
            if (dist.insurancePaymentId == data.payment.id) {
              sum += dist.payment;
            }
          }
        }
      }

      return sum;
    }

    function totalAdjustment(claim) {
      let sum = 0.0;

      for (let line of claim.claimLineItems) {
        if (line.distributions) {
          for (let dist of line.distributions) {
            if (dist.insurancePaymentId == data.payment.id) {
              sum += dist.adjustment;
            }
          }
        }
      }

      return sum;
    }

    function totalResponsibility(claim) {
      let sum = 0.0;

      for (let line of claim.claimLineItems) {
        if (line.distributions) {
          for (let dist of line.distributions) {
            if (dist.insurancePaymentId == data.payment.id) {
              sum += dist.patientResponsibility;
            }
          }
        }
      }

      return sum;
    }

    function getActionHeader(code) {
      if (data.dict.claimsActionCodes) {
        const status = data.dict.claimsActionCodes.find((item) => {
          return item.code == code;
        });
        if (status) {
          return status.description;
        }
      }
      return code;
    }

    async function deleteItem(item) {
      await deleteForClaim({
        claimId: item.id,
        insurancePaymentId: data.payment.id,
      });
      await getAll();
      calculateTotals();
      ctx.emit("savePayment");
    }

    return {
      organizationId,
      clearSearch,
      data,
      deleteItem,
      orderList,
      debounceSearch,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      selectClaim,
      getActionHeader,
    };
  },
});
